import '@/assets/scss/main.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekDay from 'dayjs/plugin/weekday';

dayjs.locale('fr');
dayjs.extend(localizedFormat);
dayjs.extend(weekDay);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Paris');

import('./vue');
